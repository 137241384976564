import { Session } from 'next-auth'
import {
  CheckoutDetails,
  UpdateContactDetailsRequest,
  Result,
  DeliveryType,
  CheckoutPaymentRequest,
  UpdateCartRequest,
  ApplyCreditResponse,
  RedeemGiftCardRequest,
} from 'shared-types'

import { ApiError } from '../helpers/apiError'
import { ICheckoutClient } from '../types/Client'
import { request } from './request'

export class CheckoutClient implements ICheckoutClient {
  async getCheckoutDetails(
    session: Session
  ): Promise<Result<CheckoutDetails, ApiError>> {
    return request<CheckoutDetails>(
      {
        url: '/bff/checkout/details',
        method: 'GET',
      },
      session
    )
  }

  async getPaymentSession(
    session: Session,
    payload: CheckoutPaymentRequest
  ): Promise<Result<CheckoutDetails, ApiError>> {
    const deliveryCode = payload.deliveryCode
      ? `deliveryCode=${payload.deliveryCode}`
      : null

    const storeKey = payload.storeKey ? `storeKey=${payload.storeKey}` : null

    return request<CheckoutDetails>(
      {
        url: `/bff/checkout/payment?${deliveryCode || storeKey}&forterCookie=${
          payload.forterCookie
        }`,
        method: 'POST',
        body: {
          storesPurchaseOrderNumber:
            payload?.storesPurchaseOrderNumber || undefined,
        },
      },
      session
    )
  }

  async updateContactDetails(
    session: Session,
    payload: UpdateContactDetailsRequest
  ): Promise<Result<CheckoutDetails, ApiError>> {
    return request<CheckoutDetails>(
      {
        url: '/bff/checkout/contact-details',
        method: 'POST',
        body: payload,
      },
      session
    )
  }

  async updateDeliveryType(
    deliveryType: DeliveryType,
    session: Session
  ): Promise<Result<CheckoutDetails, ApiError>> {
    return request<CheckoutDetails>(
      {
        url: `/bff/checkout/deliveryType/${deliveryType}`,
        method: 'PATCH',
      },
      session
    )
  }

  updateCart = (
    payload: UpdateCartRequest[],
    session: Session
  ): Promise<Result<CheckoutDetails, ApiError>> => {
    return request<CheckoutDetails>(
      {
        url: `/bff/checkout/update-cart`,
        method: 'PATCH',
        body: payload,
      },
      session
    )
  }

  addInsurance = (
    session: Session
  ): Promise<Result<CheckoutDetails, ApiError>> => {
    return request<CheckoutDetails>(
      {
        url: `/bff/checkout/insurance`,
        method: 'PATCH',
      },
      session
    )
  }

  removeInsurance = (
    session: Session
  ): Promise<Result<CheckoutDetails, ApiError>> => {
    return request(
      {
        url: '/bff/checkout/insurance',
        method: 'DELETE',
      },
      session
    )
  }

  addDiscountCode = (
    code: string,
    session: Session
  ): Promise<Result<CheckoutDetails, ApiError>> => {
    return request(
      {
        url: '/bff/checkout/discount',
        method: 'POST',
        body: {
          discountCode: code,
        },
      },
      session
    )
  }

  removeDiscountCode = (
    session: Session
  ): Promise<Result<CheckoutDetails, ApiError>> => {
    return request(
      {
        url: '/bff/checkout/discount',
        method: 'DELETE',
      },
      session
    )
  }

  addCoupon = (
    amount: number,
    session: Session
  ): Promise<Result<CheckoutDetails, ApiError>> => {
    return request<CheckoutDetails>(
      {
        url: `/bff/checkout/coupon`,
        method: 'POST',
        body: {
          amount,
        },
      },
      session
    )
  }

  removeCoupon = (
    session: Session
  ): Promise<Result<CheckoutDetails, ApiError>> => {
    return request(
      {
        url: '/bff/checkout/coupon',
        method: 'DELETE',
      },
      session
    )
  }

  async updateShippingMethod(
    deliveryCode: string,
    session: Session
  ): Promise<Result<CheckoutDetails, ApiError>> {
    return request<CheckoutDetails>(
      {
        url: `/bff/checkout/shipping-method?deliveryCode=${deliveryCode}`,
        method: 'PATCH',
      },
      session
    )
  }

  async applyCredit(
    session: Session
  ): Promise<Result<ApplyCreditResponse, ApiError>> {
    return request<ApplyCreditResponse>(
      {
        url: `/bff/checkout/credit`,
        method: 'POST',
      },
      session
    )
  }

  async updateBillingAddress(
    session: Session,
    payload: UpdateContactDetailsRequest
  ): Promise<Result<CheckoutDetails, ApiError>> {
    return request(
      {
        url: `/bff/checkout/address/billing`,
        method: 'POST',
        body: payload,
      },
      session
    )
  }

  async getLatitudeUrl(
    session: Session
  ): Promise<Result<{ url: string }, ApiError>> {
    return request(
      {
        url: '/bff/checkout/latitude/session',
        method: 'GET',
      },
      session
    )
  }

  async redeemGiftCard(
    payload: RedeemGiftCardRequest,
    session: Session
  ): Promise<Result<{ orderNumber: string }, ApiError>> {
    return request(
      {
        url: '/bff/checkout/giftcard/redeem',
        method: 'POST',
        body: payload,
      },
      session
    )
  }

  async checkGiftCardBalance(
    payload: RedeemGiftCardRequest,
    session: Session
  ): Promise<
    Result<{ account: string; balance: number; expiryDate: string }, ApiError>
  > {
    return request(
      {
        url: '/bff/checkout/giftcard/balance',
        method: 'POST',
        body: payload,
      },
      session
    )
  }
}
