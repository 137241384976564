import { Category } from './category'
import {
  BaseMoney,
  Maybe,
  Product as CtProduct,
  ProductData as CtProductData,
  ProductVariant as CtProductVariant,
  ProductCatalogData as CtProductCatalogData,
} from './commercetools'
import { RichTextContent } from './richtext'
import { ProductVariantOptionGroupComplexProps, ProductVariantOptionGroupSimpleProps } from './variants'

export interface AttributeRaw {
  name: string
  value: string
}
export type AttributeValue =
  | string
  | {
      typeId: string
      id: string
    }
  | null

export interface FormattedAttribute {
  name: string
  label: string
  value: AttributeValue
}

export type FeaturedProductSummary = ProductSummary & {
  isFeatured: boolean
  promotionId?: string
  promotionName?: string
  creativeName?: string
  creativeSlot?: string
  product?: Product
  index?: number
  category?: Category
  showAddToCartButton?: boolean
  columnSpan?: number
}
export interface PromoBannerCardSummmary extends FeaturedProductSummary {
  isPromoBanner?: boolean
  icon: Image
  description: RichTextContent
  action: {
    title: string
    href: string
  }
}

type ProductListProduct = (ProductSummary & { isFeatured: boolean }) | FeaturedProductSummary

export type ProductList = Array<ProductListProduct | PromoBannerCardSummmary>

export type GetProductListResponse = {
  products: ProductList
  currentPage: number
  totalPages?: number
  totalProducts?: number
}

type Image = {
  src: string
  width: number
  height: number
  altText: string
}

export type ProductCarouselSlide = Image & { type: string }

export type ProductTag = {
  desc?: string
  color?: string
  backgroundColor?: string
  textColor?: 'light' | 'dark'
  variant?: 'default' | 'primary' | 'secondary'
}

export type DiscountedPrice = BaseMoney & {
  validUntil?: string
}

export interface ProductVariantLegacyDoNotUse extends Omit<CtProductVariant, 'attributesRaw'> {
  attributesRaw: FormattedAttribute[]
}

export interface ProductDataLegacyDoNotUse extends Omit<CtProductData, 'allVariants'> {
  masterVariant: ProductVariantLegacyDoNotUse
  variant?: Maybe<ProductVariantLegacyDoNotUse>
}

export interface ProductCatalogDataLegacyDoNotUse extends Omit<CtProductCatalogData, 'current' | 'staged'> {
  current: Maybe<ProductDataLegacyDoNotUse>
}

export interface ProductLegacyDoNotUse extends Omit<CtProduct, 'masterData'> {
  masterData: ProductCatalogDataLegacyDoNotUse
  allVariants: ProductVariantLegacyDoNotUse[]
}

export enum ProductType {
  disruptor = 'Disruptor',
  physicalProducts = 'Physical Product',
  giftCards = 'Gift Cards',
}

export type Product = Pick<CtProductData, 'description' | 'slug'> & {
  id?: string
  name?: string
  title: CtProductData['name']
  url: CtProductData['slug']
  thumbnail?: Image
  maxQuantity?: number
  sku?: string
  finalPrice?: DiscountedPrice
  regularPrice?: BaseMoney
  rating?: number
  ratingCount?: number
  reviewCount?: number
  brandName?: string
  brandImage?: Image & { href?: string }
  category1Name?: string
  category2Name?: string
  category3Name?: string
  category4Name?: string
  category5Name?: string
  listName?: string
  listId?: string
  slug?: string
  inStock?: boolean
  masterVariant?: {
    attributesRaw: AttributeRaw[]
    sku: string
  }
  // eslint-disable-next-line camelcase
  item_status_tags?: string
  catNo?: string
  mpn?: string
  carousel?: {
    slides: ProductCarouselSlide[]
    cappasityModelId?: string
  }
  buyNowPayLaterOffers?: {
    serviceId: string
    serviceTitle: string
    offerMessage: string
    installmentPrice: BaseMoney
  }[]
  multiBuyTiers?: {
    minimumQuantity: number
    finalPrice: BaseMoney | DiscountedPrice
  }[]
  tags?: ProductTag[]
  productType?: ProductType
  variants?: (ProductVariantOptionGroupSimpleProps | ProductVariantOptionGroupComplexProps)[]
  availableForDelivery?: string
  inStockText?: string
  quantity?: number
  categories?: string[]
  objectId?: string
  queryId?: string
  externalUrl?: string
  attributes?: {
    name: string
    value: string
  }[]
}

type ProductUnion =
  | 'title'
  | 'url'
  | 'thumbnail'
  | 'sku'
  | 'finalPrice'
  | 'regularPrice'
  | 'rating'
  | 'ratingCount'
  | 'brandName'
  | 'tags'
  | 'availableForDelivery'
  | 'inStockText'
  | 'quantity'
  | 'listId'
  | 'objectId'
  | 'queryId'
  | 'externalUrl'

export type ProductSummary = Pick<Product, ProductUnion>

export interface SuggestionKeywords {
  href?: string
  title?: string
}

export interface ArticleCardContent {
  type: string
  uid?: string
  richText?: RichTextContent
}

export interface ArticleRelatedReads {
  title: string
  articlePreview: {
    content?: Array<ArticleCardContent>
    excert?: string
  }
  isFeaturedArticle: boolean
  publishedAt: string
  timeToRead: null
  image: {
    altText: string
    height: number
    width: number
    src: string
  }
  tag: string
  url: string
  hideReadmore: boolean
  articleLink: {
    href: string
  }
}

export interface QuickSearchProductResponse {
  redirectToUrl: string
  keywordsTitle: string
  keywords: SuggestionKeywords[]
  products: Product[]
  categoriesTitle: string
  categories: ProductCategory[]
  articles: ArticleRelatedReads[]
  articlesViewAllLink: {
    href: string
    title: string
  }
  noRelatedArticlesDescription: string
  noRelatedArticlesTitle: string
  allArticlesAndGuidesLink: {
    href: string
    title: string
  }
  allProductsLink: {
    href: string
    title: string
  }
  allCategoriesLink: {
    href: string
    title: string
  }
  queryId: string
}

export interface ProductCategory {
  title: string
  image?: Image
  backgroundcolor?: string
  viewMoreLink: {
    href: string
    title: string
    color?: string
  }
}

export interface ProductToolTip {
  name?: string
  title?: string
  id: string
  containsAllProducts?: boolean
}

export interface ProductFeature {
  value: string
  valueCode: string
  code: string
  name: string
  attributeType: string
  isrange: boolean
  multivalued: boolean
  unit: string
}

export interface WorkatoSubscribeToProductAlertsRequest {
  email: string
  notificationtype: string
  company: string
  prodcode: string
}
